export const monthNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
];

const monthNamesShort = [
  'Янв',
  'Фев',
  'Мар',
  'Апр',
  'Май',
  'Июн',
  'Июл',
  'Авг',
  'Сен',
  'Окт',
  'Ноя',
  'Дек'
];

const weekdayNames = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  ' Среда',
  'Четверг',
  'Пятница',
  'Суббота'
];

const weekdayNamesShort = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

const periodShortcuts = [
  {
    text: 'Сегодня',
    onClick() {
      const start = new Date();
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);

      const end = new Date();

      return [start, end];
    }
  },

  {
    text: 'Вчера',
    onClick() {
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24);
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);

      const end = new Date();
      end.setTime(end.getTime() - 3600 * 1000 * 24);
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);

      return [start, end];
    }
  },

  // {
  //   text: 'Последние 7 дней',
  //   onClick() {
  //     const start = new Date();
  //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
  //     start.setHours(0);
  //     start.setMinutes(0);
  //     start.setSeconds(0);

  //     const end = new Date();

  //     return [start, end];
  //   }
  // },

  // неделя
  {
    text: 'Текущая неделя',
    onClick() {
      const currentDate = new Date();
      const firstDay = currentDate.getDate() - currentDate.getDay() + 1;
      const lastDay = firstDay + 6;

      const start = new Date(currentDate.setDate(firstDay));
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);

      const end = new Date(currentDate.setDate(lastDay));
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);

      return [start, end];
    }
  },

  {
    text: 'Предыдущая неделя',
    onClick() {
      const currentDate = new Date();

      const lastDay = currentDate.getDate() - currentDate.getDay();
      const firstDay = lastDay - 6;

      const start = new Date(currentDate.setDate(firstDay));
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);

      const end = new Date(currentDate.setDate(lastDay));
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);

      return [start, end];
    }
  },

  // месяц
  {
    text: 'Текущий месяц',
    onClick() {
      const currentDate = new Date();

      const start = new Date();
      start.setDate(1);
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);

      const end = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);

      return [start, end];
    }
  },

  {
    text: 'Предыдущий месяц',
    onClick() {
      const currentDate = new Date();

      const start = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);

      const end = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      );
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);

      return [start, end];
    }
  },

  // квартал
  // {
  //   text: '1 квартал',
  //   onClick() {
  //     const currentDate = new Date();

  //     const start = new Date(currentDate.getFullYear(), 0, 1);
  //     start.setHours(0);
  //     start.setMinutes(0);
  //     start.setSeconds(0);

  //     const end = new Date(currentDate.getFullYear(), 3, 0);
  //     end.setHours(23);
  //     end.setMinutes(59);
  //     end.setSeconds(59);

  //     return [start, end];
  //   }
  // },

  // {
  //   text: '2 квартал',
  //   onClick() {
  //     const currentDate = new Date();

  //     const start = new Date(currentDate.getFullYear(), 3, 1);
  //     start.setHours(0);
  //     start.setMinutes(0);
  //     start.setSeconds(0);

  //     const end = new Date(currentDate.getFullYear(), 6, 0);
  //     end.setHours(23);
  //     end.setMinutes(59);
  //     end.setSeconds(59);

  //     return [start, end];
  //   }
  // },

  // {
  //   text: '3 квартал',
  //   onClick() {
  //     const currentDate = new Date();

  //     const start = new Date(currentDate.getFullYear(), 6, 1);
  //     start.setHours(0);
  //     start.setMinutes(0);
  //     start.setSeconds(0);

  //     const end = new Date(currentDate.getFullYear(), 9, 0);
  //     end.setHours(23);
  //     end.setMinutes(59);
  //     end.setSeconds(59);

  //     return [start, end];
  //   }
  // },

  // {
  //   text: '4 квартал',
  //   onClick() {
  //     const currentDate = new Date();

  //     const start = new Date(currentDate.getFullYear(), 9, 1);
  //     start.setHours(0);
  //     start.setMinutes(0);
  //     start.setSeconds(0);

  //     const end = new Date(currentDate.getFullYear() + 1, 0, 0);
  //     end.setHours(23);
  //     end.setMinutes(59);
  //     end.setSeconds(59);

  //     return [start, end];
  //   }
  // },

  {
    text: 'Текущий квартал',
    onClick() {
      const currentDate = new Date();
      const quarter = Math.floor((currentDate.getMonth() + 3) / 3);
      if (quarter === 1) {
        const currentDate = new Date();

        const start = new Date(currentDate.getFullYear(), 0, 1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        const end = new Date(currentDate.getFullYear(), 3, 0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);

        return [start, end];
      }

      if (quarter === 2) {
        const currentDate = new Date();

        const start = new Date(currentDate.getFullYear(), 3, 1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        const end = new Date(currentDate.getFullYear(), 6, 0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);

        return [start, end];
      }

      if (quarter === 3) {
        const currentDate = new Date();

        const start = new Date(currentDate.getFullYear(), 6, 1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        const end = new Date(currentDate.getFullYear(), 9, 0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);

        return [start, end];
      }

      if (quarter === 4) {
        const start = new Date(currentDate.getFullYear(), 9, 1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        const end = new Date(currentDate.getFullYear() + 1, 0, 0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);

        return [start, end];
      }
    }
  },

  {
    text: 'Предыдущий квартал',
    onClick() {
      const currentDate = new Date();
      const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3);
      let nextQuarter = '';

      if (currentQuarter === 1) {
        nextQuarter = 4;
      } else {
        nextQuarter = currentQuarter - 1;
      }

      console.log(nextQuarter);

      if (nextQuarter === 1) {
        const currentDate = new Date();

        const start = new Date(currentDate.getFullYear(), 0, 1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        const end = new Date(currentDate.getFullYear(), 3, 0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);

        return [start, end];
      }

      if (nextQuarter === 2) {
        const currentDate = new Date();

        const start = new Date(currentDate.getFullYear(), 3, 1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        const end = new Date(currentDate.getFullYear(), 6, 0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);

        return [start, end];
      }

      if (nextQuarter === 3) {
        const currentDate = new Date();

        const start = new Date(currentDate.getFullYear(), 6, 1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        const end = new Date(currentDate.getFullYear(), 9, 0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);

        return [start, end];
      }

      if (nextQuarter === 4) {
        const start = new Date(currentDate.getFullYear() - 1, 9, 1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        const end = new Date(currentDate.getFullYear(), 0, 0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);

        return [start, end];
      }
    }
  },

  // год
  {
    text: 'Текущий год',
    onClick() {
      const currentDate = new Date();

      const start = new Date(currentDate.getFullYear(), 0, 1);
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);

      const end = new Date(currentDate.getFullYear() + 1, 0, 0);
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);

      return [start, end];
    }
  },

  {
    text: 'Предыдущий год',
    onClick() {
      const currentDate = new Date();

      const start = new Date(currentDate.getFullYear() - 1, 0, 1);
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);

      const end = new Date(currentDate.getFullYear(), 0, 0);
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);

      return [start, end];
    }
  }
];

export const daysOfWeek = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
];

export default {
  monthNames,
  monthNamesShort,
  weekdayNames,
  weekdayNamesShort,
  periodShortcuts
};

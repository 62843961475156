<template>
  <div class="datepicker">
    <label class="datepicker__label" v-if="label">{{ label }}</label>
    <vue-datepicker
      ref="datepicker"
      :value="processedValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :disabledDate="disabledDate"
      :disabledTime="disabledTime"
      :type="type"
      :range="range"
      :range-separator="rangeSeparator"
      :format="format"
      :value-type="valueType"
      :time-title-format="timeTitleFormat"
      :lang="lang"
      :shortcuts="showShortcuts && range ? shortcuts : []"
      :confirm="confirm"
      :confirm-text="confirmText"
      @input="onInput"
      @confirm="onConfirm"
      :popup-style="{ top: '100%', left: 0 }"
      :append-to-body="false"
    />
    <div class="datepicker__target-wrapper">
      <div 
        ref="target"
        class="datepicker__target"
      ></div>
    </div>
  </div>
</template>

<script>
import VueDatepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import time from '@/utils/datepicker.js';
import {
  MONTH_NAMES_SHORT,
  WEEKDAY_NAMES,
  WEEKDAY_NAMES_SHORT,
} from '@/constants/datepicker.js';

export default {
  name: 'SmDatepicker',

  components: {
    VueDatepicker,
  },

  props: {
    value: {
      type: [String, Date, Array],
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    /**
     * date | datetime | year | month | time | week
     */
    type: {
      type: String,
      default: 'date',
    },

    /**
     * Show single or range date pick
     */
    range: {
      type: Boolean,
      default: false,
    },

    rangeSeparator: {
      type: String,
      default: ' - ',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    disabledDate: {
      type: Function,
      required: false,
    },

    disabledTime: {
      type: Function,
      required: false,
    },

    format: {
      type: String,
      default: 'DD.MM.YYYY',
    },

    valueType: {
      type: String,
      default: 'DD.MM.YYYY',
    },

    timeTitleFormat: {
      type: String,
      default: 'DD.MM.YYYY',
    },

    /**
     * Оverride the default locale
     */
    lang: {
      type: Object,
      default: () => ({
        formatLocale: {
          months: MONTH_NAMES_SHORT,
          monthsShort: MONTH_NAMES_SHORT,
          weekdays: WEEKDAY_NAMES,
          weekdaysShort: WEEKDAY_NAMES_SHORT,
          weekdaysMin: WEEKDAY_NAMES_SHORT,
          firstDayOfWeek: 1,
          firstWeekContainsDate: 1,
        },
      }),
    },

    confirm: {
      type: Boolean,
      default: true,
    },

    confirmText: {
      type: String,
      default: 'Применить',
    },

    showShortcuts: {
      type: Boolean,
      default: false,
    },

    shortcuts: {
      type: Array,
      default: () => time.periodShortcuts,
    },
  },

  computed: {
    processedValue() {
      return this.range ? this.value.split('-') : this.value;
    },
  },

  mounted() {
    this.initDatepickerPopupPosition();
  },

  methods: {
    onInput(value) {
      if (this.range) return this.$emit('input', value.join('-'));
      this.$emit('input', value);
    },

    onConfirm() {
      this.$emit('onConfirm');
    },

    initDatepickerPopupPosition() {
      const el = this.$refs.datepicker?.$refs?.popup?.$el || null;

      if (!this.$refs.target || !el) return;

      this.$refs.target.appendChild(el);
    },
  },
};
</script>

<style lang="scss">
.datepicker {
  position: relative;
  width: 100%;

  .mx-input {
    width: 100%;
    height: 36px;
    padding: 7px 12px;

    font-size: 16px;
    line-height: 1;
    font-weight: 400;

    border: 1px solid var(--gray);
    border-radius: 10px;

    background-color: var(--white);

    outline: none;
  }

  .mx-input:disabled {
    cursor: default;
  }

  .mx-input:focus {
    border: 1px solid var(--blue);
  }

  .mx-input:disabled {
    color: var(--black);

    background-color: rgba(var(--rgb-gray), 0.2);
  }

  .mx-datepicker {
    width: 100%;
    display: block;
  }

  .mx-icon-calendar svg {
    fill: var(--gray);
  }

  .mx-input:focus + .mx-icon-calendar svg {
    fill: var(--blue);
  }
}

.mx-datepicker-sidebar {
  width: 170px;
}

.mx-datepicker-sidebar + .mx-datepicker-content {
  margin-left: 170px;
}

.mx-datepicker-main {
  background-color: var(--blue);
}

.mx-datepicker-body,
.mx-datepicker-footer {
  background-color: var(--white);
}

.mx-datepicker-sidebar .mx-btn {
  display: block;
  width: 100%;

  color: var(--white);
  white-space: nowrap;

  border-radius: unset;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.datepicker__label {
  display: inline-block;
  margin-bottom: 10px;

  color: var(--gray);
}

.datepicker__target-wrapper {
  position: relative;
  z-index: 1;

  width: 100%;
}

.datepicker__target {
  position: fixed;
}
</style>
